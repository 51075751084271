import React from "react";
import { Link } from "react-router-dom";
import ProfileHeader from "../../components/profile-header/index";
import ProfileInfo from "../../components/profile-info/index";
import Session from "../../img/session.svg";
import Fon from "../../img/fon.svg";
import Ava from "../../img/ava.svg";
import Close from "../../img/close.svg";
import axios from "axios";
import Popup from "../../components/popup";
import UserAvatarsPopup from "../../components/user-avatars-popup";

function Setting({ avatar, name, status, setAvatar }) {
  const [popup, setPopup] = React.useState(false);
  const [userAvatarsPopup, setUserAvatarsPopup] = React.useState(false);
  const [sessions, setSession] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER}/api/v1/auth/session/active`, {
        headers: { Authorization: `${localStorage.getItem("token")}` },
      })
      .then(function (response) {
        setSession(response?.data?.sessions);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const imageUpload = (e) => {
    const file = e.target.files[0];
    getBase64(file).then((base64) => {
      localStorage["fileBase64"] = base64;
    });
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <ProfileHeader avatar={avatar} name={name} status={status} />
      <div className="profile-info__wrapper">
        <div onClick={() => setUserAvatarsPopup(true)} className="profile-info__link profile-info__name">
          <img src={Ava} />
          <Link>Изменить фото</Link>
        </div>
        <div className="profile-info__link">
          <img src={Fon} />
          <Link to="/">Выбрать фон чатов</Link>
          <input
            type="file"
            id="imageFile"
            name="imageFile"
            title= " "
            onChange={imageUpload}
          />
        </div>
        <div onClick={() => setPopup(true)} className="profile-info__link">
          <img src={Session} />
          <Link>Активные сессии</Link>
        </div>
      </div>

      <UserAvatarsPopup setAvatar={setAvatar} visible={userAvatarsPopup} setVisible={setUserAvatarsPopup} />

      <Popup containerClassName="popup__setting" visible={popup} setVisible={setPopup}>
          <div className="popup__setting">
            <div className="popup__setting-header">
              <span>Настройки сессий</span>
              <span onClick={() => setPopup(false)}>
                <img src={Close} />
              </span>
            </div>
            <div className="popup__setting-body">
              {sessions.map((session, index) => (
                <div className="session__item" key={index}>
                  <div>{session.this_is ? "Текущая" : "Сторонняя"}</div>
                  <span>{session.device_id}</span>
                  <span>
                    {" "}
                    {new Date(session.last_active * 1000).toLocaleDateString(
                      "ru-RU"
                    )}
                  </span>
                  <img className="session__item-close" src={Close} />
                </div>
              ))}
            </div>
          </div>
        </Popup>
    </>
  );
}

export default Setting;
