import React from "react";
import { redirect, useNavigate } from "react-router-dom";
import axios from "axios";
import useSIP from "../../hooks/useSIP";
import { useDispatch, useSelector } from "react-redux";
import { setPhoneData } from "../../reducers/phone-reduser";

function PrivateRoute({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {SIP} = useSIP({
    onIncomingCall(session) {
      dispatch(setPhoneData({incomingCallPopup: true, companion: null}))
    },
  });

  React.useEffect(() => {
    if (localStorage.getItem("token")) {
      axios
        .get(`${process.env.REACT_APP_SERVER}/api/v1/iam`, {
          headers: { Authorization: `${localStorage.getItem("token")}` },
        })
        .catch(() => navigate("/"));
    } else {
      localStorage.removeItem("token");
      redirect("/");
    }
  }, []);

  return children;
}

export default PrivateRoute;
