import React from "react";
import { Link } from "react-router-dom";
import '../../styles/index.css';
import axios from "axios";

function UserInfo({companion, id}) {
  const [loader, setLoader] = React.useState(true);
  const [userInfo, setUserInfo] = React.useState('');

  React.useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER}/api/v1/user/${window.location.pathname === "/chat" ? companion : id}`, {
        headers: { Authorization: `${localStorage.getItem("token")}` },
      })
      .then(function (response) {
        setUserInfo(response.data)
        setLoader(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  
  return (
      <>
      {loader == true ? (
        <div className="loader__wrapper">
          <div className="loader" id="loader-1"></div>
        </div>
      )
      : (
        <div className="user-info__wrapper">
        <div className="user-info__text">
            <span>Организация</span>
            <div>{userInfo.organization == null ? "Организация не указана" : userInfo.organization}</div>
        </div>
        <div className="user-info__text">
            <span>Мобильный телефон</span>
            <div>{userInfo.mobile_phone == null ? "Мобильный телефон не указан" : userInfo.mobile_phone}</div>
        </div>
        <div className="user-info__text">
            <span>Рабочий телефон</span>
            <div>{userInfo.work_phone == null ? "Рабочий телефон не указан" : userInfo.work_phone}</div>
        </div>
        </div>
      )
}
      </>
  );
}

export default UserInfo;