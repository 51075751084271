import React from "react";
import IndexHeader from "../../components/index-header/index"
import Contact from "../../components/contact/index"
import Menu from "../../components/menu/index"

function Contacts({ avatar }) {
  
  return (
    <>
     <IndexHeader avatar={avatar} />
     <Contact />
     <Menu />
    </>
  );
}

export default Contacts;