import React from "react";
import IndexHeader from "../../components/index-header/index"
import Menu from "../../components/menu/index"
import ConferenceItem from "../../components/conference-item/index"

function Conference({ avatar }) {
  
  return (
    <>
        <IndexHeader avatar={avatar} />
        <ConferenceItem />
        <Menu />
    </>
  );
}

export default Conference;