import React, { useState, useEffect } from "react";
import Popup from "../popup";
import axios from "axios";
import UploadAvatarPopup from "../upload-avatar-popup";

const UserAvatarsPopup = ({
  visible,
  setVisible,
  setAvatar: setCurrentAvatar,
}) => {
  const [avatars, setAvatars] = useState([]);
  const [avatar, setAvatar] = useState("");
  const [uploadAvatarPopup, setUploadAvatarPopup] = useState(false);
  const [avatarPopupSubmit, setAvatarPopupSubmit] = useState("");

  useEffect(() => {
    if (visible) {
      axios
        .get(`${process.env.REACT_APP_SERVER}/api/v1/avatar/all`, {
          headers: { Authorization: `${localStorage.getItem("token")}` },
        })
        .then(function (response) {
          setAvatars(response.data?.avatars);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [visible]);

  const selectAvatar = async (avatar) => {
    setAvatarPopupSubmit("visible");
    const { status } = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/v1/avatar/selected/${avatar.avatar_id}`,
      {
        headers: { Authorization: `${localStorage.getItem("token")}` },
      }
    );
    if (status === 200) {
      const newAvatars = JSON.parse(JSON.stringify(avatars));
      const currentAvatarIndex = newAvatars.findIndex((o) => o.is_selected);
      newAvatars[currentAvatarIndex].is_selected = false;
      const newAvatarIndex = newAvatars.findIndex(
        (o) => o.avatar_id === avatar.avatar_id
      );
      newAvatars[newAvatarIndex].is_selected = true;
      setAvatars(newAvatars);
      setCurrentAvatar(newAvatars[newAvatarIndex].link);
      setVisible(false);
      setAvatarPopupSubmit("")
    }
  };

  const selectAvatarGo = (avatar) => {
    setAvatarPopupSubmit("visible");
    setAvatar(avatar);
  };

  const selectAvatarNo = (avatar) => {
    setAvatarPopupSubmit("no");
  };

  return (
    <>
      <Popup
        containerClassName={"popup-avatars__wrapper"}
        visible={visible}
        setVisible={setVisible}
      >
        {avatars.map((avatar, index) => (
          <img
            onClick={() => selectAvatarGo(avatar)}
            data-selected={avatar.is_selected}
            src={avatar.link}
            data-selected={avatar.is_selected}
            key={avatar.avatar_id}
          />
        ))}
        <button onClick={() => setUploadAvatarPopup(true)}>
          Добавить аватар
        </button>
        <div
          className={
            avatarPopupSubmit === "visible"
              ? "popup-submit-photo__go"
              : "popup-submit-photo__not-go"
          }
        >
          <div className="popup-submit-photo__go-wrap">
            <span>Подтвердите изменение</span>
          <button onClick={() => selectAvatar(avatar)}>Изменить аватар</button>
          <button onClick={() => selectAvatarNo(avatar)}>отмена</button>
          </div>
        </div>
      </Popup>
      <UploadAvatarPopup
        visible={uploadAvatarPopup}
        setVisible={setUploadAvatarPopup}
        setAvatars={setAvatars}
        setCurrentAvatar={setCurrentAvatar}
      />
    </>
  );
};

export default UserAvatarsPopup;
