import { UA, WebSocketInterface, debug as JsSIPDebug } from "jssip";
import axios from "axios";

const incomingCallAudio = new window.Audio("");
incomingCallAudio.loop = true;
incomingCallAudio.crossOrigin = "anonymous";

class SIP {
  static phone = null;
  static sipInfo = null;
  static socket = null;
  static config = null;
  static session = null;
  static remoteAudio = null;
  static isConnected = false;
  static callOptions = { mediaConstraints: { audio: true, video: false } };
  static events = {
    onIncomingCall: [],
    onAccepted: [],
    onProgress: [],
    onEnded: [],
    onSessionConfirmed: [],
    onAddTrack: [],
  };

  static #triggerEvent = (eventName, ...args) => {
    for (const handler of this.events[eventName]) {
      handler.call(this, ...args);
    }
  };

  static #onSessionRefer = (data) => {
    this.session.terminate();
    this.phone.call(data.request.refer_to._uri._user, this.callOptions);
  };

  static #onSessionConfirmed = async () => {
    this.#triggerEvent("onSessionConfirmed");
    const localStream = this.session.connection.getLocalStreams()[0];
    const dtmfSender = this.session.connection.createDTMFSender(
      localStream.getAudioTracks()[0]
    );
    this.session.sendDTMF = (tone) => {
      dtmfSender.insertDTMF(tone);
    };

    this.updateUI();
  };

  static #onSessionPeerconnection = (e) => {
    console.log("peerconnection", e);
    e.peerconnection.onaddstream = (e) => {
      console.log("addstream", e);
      this.remoteAudio.srcObject = e.stream;
      this.remoteAudio.play();
    };

    e.peerconnection.ontrack = (ev) => {
     this.#triggerEvent('onAddTrack', ev)
     console.log(ev, "addTrack 2")
    };

    const remoteStream = new MediaStream();
    console.log(e.peerconnection.getReceivers());
    for (const receiver of e.peerconnection.getReceivers()) {
      remoteStream.addTrack(receiver.track);
    }
  };

  static #onNewRTCSession = async (ev) => {
    this.closeSession();
    this.session = ev.session;

    this.session.on("ended", (e) => {
      this.#triggerEvent("onEnded", e);
      this.closeSession(e);
    });
    this.session.on("failed", (e) => {
      this.#triggerEvent("onEnded", e);
      this.closeSession(e);
    });
    this.session.on("accepted", (e) => {
      this.#triggerEvent("onAccepted", e);
      this.updateUI();
    });
    this.session.on("refer", this.#onSessionRefer);
    this.session.on("confirmed", this.#onSessionConfirmed);
    this.session.on("peerconnection", this.#onSessionPeerconnection);
    if (this.session.direction !== "incoming") {
      console.log("con", this.session.connection);
      this.session.connection.addEventListener("addstream", (e) => {
        this.remoteAudio.srcObject = e.stream;
      });
      SIP.session.connection.addEventListener("track", (ev) => {
        this.#triggerEvent('onAddTrack', ev)
      })
    } else {
      this.#triggerEvent("onIncomingCall", this.session);
    }
    this.updateUI();

    this.session.on("progress", (e) => {
      this.#triggerEvent("onProgress", e);
    });
    window.addEventListener("beforeunload", () => {
      this.closeSession();
      this.phone.terminateSessions();
      this.phone.stop();
    });
    window.addEventListener("leave", () => {
      this.closeSession();
      this.phone.terminateSessions();
      this.phone.stop();
    });
    console.log("rtc connected");
  };

  static async connect() {
    this.isConnected = true;
    await this.#getSipInfo();
    console.log("connect done");
    this.socket = new WebSocketInterface(`wss://${this.sipInfo.wss}`);
    this.config = {
      sockets: [this.socket],
      uri: `sip:${this.sipInfo.sip}@${this.sipInfo.domain}`,
      registrar_server: this.sipInfo.domain,
      password: this.sipInfo.password,
      username: this.sipInfo.sip,
      register_expires: 60,
      contact_uri: this.sipInfo.name,
      // register: true,
      user_agent: "cosco 2.0",
    };
    this.remoteAudio = new window.Audio();
    this.remoteAudio.autoplay = true;
    this.remoteAudio.crossOrigin = "anonymous";
    document.body.appendChild(this.remoteAudio);
    if (this.config.password) {
      // JsSIPDebug.enable("JsSIP:*"); //logaet gavno v konsol
      this.phone = new UA(this.config);
      this.phone.on("registrationFailed", (ev) => {
        // alert("Registering on SIP server failed with error: " + ev.cause);
        this.config.uri = null;
        this.config.password = null;
      });
      this.phone.on("newRTCSession", this.#onNewRTCSession);
      this.phone.on("newMessage", (e) => console.log(e));
      this.phone.start();
    }
    this.updateUI();
  }

  static async #getSipInfo() {
    const res = await axios.get(`${process.env.REACT_APP_SERVER}/api/v1/iam`, {
      headers: { Authorization: `${localStorage.getItem("token")}` },
    });
    const {
      ats_info: {
        ats_domain: domain,
        ats_password: password,
        ats_number: sip,
        ats_wss: wss,
      },
      i_am: { guid },
    } = res.data;
    this.sipInfo = {
      domain,
      password,
      sip,
      wss,
      guid,
    };
  }

  static closeSession(e = null) {
    console.log("start close session");
    if (!this.session?.isEnded()) {
      this.session?.terminate();
    }
    this.session = null;
    console.log("end close session");
  }

  static updateUI() {
    if (this.config.password) {
      if (this.session) {
        if (this.session.isInProgress()) {
          if (this.session.direction === "incoming") {
            // console.log(this.session);
          }
        } else if (this.session?.isEnded()) {
          this.#triggerEvent("onEnded");
        }
      }
    }
  }
}

export default SIP;
