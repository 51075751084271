import cl from "./Popup.module.css";
import { createPortal } from "react-dom";

const Popup = ({
  visible,
  setVisible = s => s,
  children,
  className,
  containerClassName,
  background = true,
  onClose = () => null
}) => {
  const closePopup = () => {
    setVisible(false);
    onClose();
  };

  return createPortal(
    <div
      onClick={closePopup}
      className={`${cl.popupScreen} ${className ?? ""}`}
      data-visible={visible}
      data-background={background}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`${cl.popupContainer} ${containerClassName ?? ""}`}
      >
        {children}
      </div>
    </div>,
    document.querySelector("#popups")
  );
};

export default Popup;
