import React from "react";
import { Link } from "react-router-dom";
import '../../styles/index.css';
import Exit from "../../img/exit.svg";
import History from "../../img/callhistory.svg";
import Setting from "../../img/setting.svg";

function ProfileInfo({name}) {

  const exit = () => {
    localStorage.removeItem('token')
    window.location.replace("/");
  };

  return (
      <div className="profile-info__wrapper">
        {/* <div className="profile-info__name">{name}</div> */}
        <div className="profile-info__link profile-info__name"><img src={Setting} /><Link to="/profile/setting">Настройки</Link></div>
        <div className="profile-info__link"><img src={History} /><Link to="/profile/history">История звонков</Link></div>
        <div  onClick={() => exit()} className="profile-info__link"><img src={Exit} /><Link>Выход</Link></div>
      </div>
  );
}

export default ProfileInfo;