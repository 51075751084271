import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import check from "../../img/check.svg";
import nocheck from "../../img/nocheck.svg";
import file from "../../img/file.svg";
import mobile from "../../img/mobile.svg";
import mesotv from "../../img/mesotv.svg";
import mespere from "../../img/mesper.svg";
import meskop from "../../img/mescopy.svg";
import mesred from "../../img/mesred.svg";
import mesdel from "../../img/mesdel.svg";
import axios from "axios";
import { useDebounce } from "use-debounce";

const addZero = (number) => {
  return number < 10 ? "0" + number : number;
};

function Correspondences({
  loader,
  correspondence,
  companion,
  id,
  page,
  pageCount,
  getMessages,
  editMessage,
  isVisiualEditId,
  isVisiualEdit,
  onClicEdit,
  onCloseEdit,
  deleteMessage,
  answerMessage,
  sendMyAnswer
}) {
  const containerRef = React.useRef(null);
  const messagesMountedRef = useRef(false);
  const [isScrollTop, setIsScrollTop] = useState(false);
  // const [isVisiualEditId, setIsVisiualEditId] = useState("");
  // const [isVisiualEdit, setIsVisiualEdit] = useState(false);
  const [debouncedIsScrollTop] = useDebounce(isScrollTop, 700);
  const lastMessageRef = useRef(null);
  const oldLastMessageRef = useRef(null);

  const onScroll = (e) => {
    if (messagesMountedRef.current) {
      setIsScrollTop(e.currentTarget.scrollTop === 0);
    }
  };

  // const onClicEdit = (e) => {
  //   setIsVisiualEditId(e.target.id);
  //   setIsVisiualEdit(true);
  //   var foo = document.getElementsByClassName("message__my");
  //   for (var i = 0; i < foo.length; i++) {
  //     foo[i].classList.remove("active");
  //     foo[i].classList.remove("active-top");
  //   }
  //   if (e.currentTarget.dataset.index < 3) {
  //     e.currentTarget.classList.add("active-top");
  //   } else {
  //     e.currentTarget.classList.add("active");
  //   }
  // };

  // const onCloseEdit = () => {
  //   setIsVisiualEdit(false);
  //   setIsVisiualEditId("");
  //   var foo = document.getElementsByClassName("active");
  //   var foos = document.getElementsByClassName("active-top");
  //   for (var i = 0; i < foo.length; i++) {
  //     foo[i].classList.remove("active");
  //   }
  //   for (var i = 0; i < foos.length; i++) {
  //     foos[i].classList.remove("active-top");
  //   }
  // };

  const getNewMessages = async () => {
    oldLastMessageRef.current = lastMessageRef.current;
    await getMessages();
  };

  useEffect(() => {
    if (debouncedIsScrollTop) {
      void getNewMessages();
    }
  }, [debouncedIsScrollTop]);

  // console.log(correspondence)
  useEffect(() => {
    if (
      !messagesMountedRef.current &&
      correspondence.length &&
      containerRef.current
    ) {
      messagesMountedRef.current = true;
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }

    if (oldLastMessageRef.current) {
      const oldLastMessage = document.querySelector(
        `[data-id='${oldLastMessageRef.current.dataset.id}']`
      );
      oldLastMessage.scrollIntoView();
    }
  }, [correspondence]);
  

  return (
    <>
      {loader ? (
        <div className="loader__wrapper">
          <div className="loader" id="loader-1"></div>
        </div>
      ) : (
        <div>
          {!correspondence.length ? (
            <div className="history-call__item--no">
              История сообщений пуста
            </div>
          ) : (
            <div
              onScroll={onScroll}
              style={
                localStorage.getItem("fileBase64")
                  ? {
                      background: `url("${localStorage.getItem(
                        "fileBase64"
                      )}")`,
                      backgroundSize: "cover",
                    }
                  : { background: "unset" }
              }
              ref={containerRef}
              className="correspondences__wrapper"
            >
              {correspondence.map((correspondenceMessage, index) => {
                const prevCorrespondence = correspondence[index - 1];
                const correspondenceDate = new Date(
                  correspondenceMessage?.dtime * 1000
                );
                const prevCorrespondenceDate = new Date(
                  prevCorrespondence?.dtime * 1000
                );
                let separator = false;

                if (
                  correspondenceDate.getDate() >
                    prevCorrespondenceDate.getDate() ||
                  index === 0
                ) {
                  separator = true;
                }

                return (
                  <>
                    {separator && (
                      <span className="correspondences__date-item">
                        {correspondenceDate.toLocaleDateString("ru-RU") === 'Invalid Date' ? "Сейчас" : correspondenceDate.toLocaleDateString("ru-RU")}
                      </span>
                    )}
                    {correspondenceMessage?.message_id === isVisiualEditId && (
                      <div
                        onClick={onCloseEdit}
                        className={
                          isVisiualEdit
                            ? "edit__message-wrapper"
                            : "edit__message-wrapper edit__message-wrapper--none"
                        }
                      ></div>
                    )}
                    {correspondenceMessage?.type ?
                    <div
                      id={correspondenceMessage?.message_id}
                      onClick={onClicEdit}
                      data-index={index}
                      data-id={correspondenceMessage?.message_id}
                      key={JSON.stringify(correspondenceMessage)}
                      ref={index === 0 ? lastMessageRef : null}
                      className={
                        correspondenceMessage?.user_from === companion
                          ? "message__you"
                          : "message__my"
                      }
                    >
                      <ul
                        className={
                          correspondenceMessage?.message_id === isVisiualEditId
                            ? "edit__message"
                            : "edit__message edit__message--none"
                        }
                      >
                        <li onClick={() => answerMessage(correspondenceMessage?.message?.text, correspondenceMessage?.message_id, correspondenceMessage?.dialog_id)}>
                          <img src={mesotv} alt="Ответить" />
                          Ответить
                        </li>
                        <li>
                          <img src={mespere} alt="Переслать" />
                          Переслать
                        </li>
                        <li>
                          <img src={meskop} alt="Скопировать" />
                          Скопировать
                        </li>
                        <li onClick={() => editMessage(correspondenceMessage?.message?.text, correspondenceMessage?.message_id, correspondenceMessage?.dialog_id)}>
                          <img src={mesred} alt="Редактировать" />
                          Редактировать
                        </li>
                        <li onClick={() => deleteMessage(correspondenceMessage?.message_id, correspondenceMessage?.dialog_id)}>
                          <img src={mesdel} alt="Удалить" />
                          Удалить
                        </li>
                      </ul>
                     
                      {correspondenceMessage?.type === "text" ? (
                        <span>{correspondenceMessage?.message?.text ? correspondenceMessage?.message?.text : correspondenceMessage?.body?.text}</span>
                      ) : (
                        <div className="link-file__message__wrapper">
                          <a
                            href={correspondenceMessage?.message?.file_link}
                            className="link-file__message"
                          >
                            <img
                              src={
                                correspondenceMessage?.type === "media"
                                  ? file
                                  : mobile
                              }
                              alt="Файл"
                            />
                            {sendMyAnswer !== false ? <span>|{correspondenceMessage?.message?.reply}</span> : ''}
                            <span>{correspondenceMessage?.message?.text}</span>
                          </a>
                        </div>
                      )}

                      <div className="messenge__time-status--wrapper">
                        <span>
                          {" "}
                          {`${addZero(correspondenceDate.getHours())}:${addZero(
                            correspondenceDate.getMinutes()
                          )}`}
                        </span>
                        {correspondenceMessage?.user_from === companion ? (
                          ""
                        ) : (
                          <img
                            src={
                              correspondenceMessage?.statuses === "read"
                                ? check
                                : nocheck
                            }
                          />
                        )}
                      </div>
                    </div>
              :
              ''}
                  </>
                );
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Correspondences;
