import React, { useEffect, useState } from "react";
import UserHeader from "../../components/user-header/index";
import Correspondences from "../../components/correspondences";
import axios from "axios";
import useWebSocket from "react-use-websocket";
import addImg from "../../img/addimg.svg";
import addSmile from "../../img/addsmile.svg";
import addVoice from "../../img/addvoice.svg";
import send from "../../img/send.svg";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import Close from "../../img/close.svg";

const toBase64 = async (data) => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = function () {
      res(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
      rej(error);
    };
  });
};

function Correspondence() {
  const [file, setFile] = React.useState(null);
  const [smile, setSmile] = React.useState(false);
  const [loader, setLoader] = React.useState(true);
  const [correspondence, setCorrespondence] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [id, setId] = useState(`${window.location.href.split("?")[1]}`);
  const companion = window.location.href.split("?")[2];
  const [message, setMessage] = React.useState("");
  const [messageSended, setMessageSended] = React.useState(false);
  const [editMessageItem, setEditMessageItem] = React.useState(false);
  const [editMessageId, setEditMessageId] = React.useState("");
  const [editMessageDialogId, setEditMessageDialogId] = React.useState("");
  const [editMessageText, setEditMessageText] = React.useState("");
  const [isVisiualEditId, setIsVisiualEditId] = useState("");
  const [isVisiualEdit, setIsVisiualEdit] = useState(false);
  const [sendMyEdit, setSendMyEdit] = useState(false);
  const [sendMyAnswer, setSendMyAnswer] = useState(false);

  const getMessages = async (newPage = page + 1, limit) => {
    const { data: newMessages } = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/v2/dialogs/history/${id}?limit=20&page=${page}`,
      {
        headers: { Authorization: `${localStorage.getItem("token")}` },
      }
    );
    const { totalPages } = newMessages.pagination;
    if (newPage > totalPages) {
      return;
    }
    setPage(newPage);
    setCorrespondence((prev) => [...newMessages.data.message, ...prev]);
  };

  const reloadMessages = async (newPage = 1, limit) => {
    //Запрос для обновления редактированных сообщений
    const { data: newMessages } = await axios.get(
      `${process.env.REACT_APP_SERVER}/api/v2/dialogs/history/${id}?limit=20&page=1`,
      {
        headers: { Authorization: `${localStorage.getItem("token")}` },
      }
    );
    setCorrespondence((prev) => [...newMessages.data.message]);
  };

  useEffect(() => {
    if (messageSended) {
      document
        .querySelector(".correspondences__wrapper")
        .lastElementChild.scrollIntoView();
      setMessageSended(false);
    }
  }, [messageSended]);

  const onFileInputChange = async (e) => {
    const fileName = e.target.files[0].name;

    const soketFileSend = {
      message: {
        for_user: `${companion}`,
        dialog_id: `${id}`,
        type: "media",
        body: `${fileName}`,
      },
      token: `${localStorage.getItem("token")}`,
      topic: "message.sent",
    };

    sendMessage(JSON.stringify(soketFileSend));
    setFile(await toBase64(e.target.files[0]));
  };

  const soketAuth = {
    topic: "out.auth.check",
    token: `${localStorage.getItem("token")}`,
  };

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    `${process.env.REACT_APP_SERVER_SOKET}`,
    {
      onMessage(event) {
        let message = JSON.parse(event.data);
        if (message.topic === "in.dialog.create") {
          setId(message.unit.dialog_id);
        }
        if (message.topic === "out.message.add") {
          const deliviredPayload = {
            topic: "out.message.delivered",
            unit: {
              dialog_id: `${message.unit.dialog_id}`,
              message_id: `${message.unit.message_id}`,
            },
          };
          sendMessage(JSON.stringify(deliviredPayload));
          if (correspondence.length) {
            setCorrespondence((prev) => [...prev, message.unit]);
          }
        } else if (message.topic === "in.message.add") {
          if (message.unit.type === "media") {
            axios.post(
              `https://cosco.abr95.ru:3333/API/v1/load/${message.body.messages.file_id}`,
              { data: file.split(",")[0], base64: file.split(",")[1] },
              {
                headers: { Authorization: `${localStorage.getItem("token")}` },
              }
            );
          }
          setCorrespondence((prev) => [...prev, message.unit]);
          setMessage("");
          setMessageSended(true);
        }
      },
      onOpen(event) {
        sendMessage(JSON.stringify(soketAuth));
      },
    }
  );

  const soketMessageSend = {
    topic: "out.message.add",
    unit: {
      dialog_id: `${id}`,
      type: "text",
      body: {
        text: `${message}`,
        attributes: null,
      },
    },
  };

  React.useLayoutEffect(() => {
    const soketMessageSendNewDialog = {
      topic: "out.dialog.create",
      unit: {
        user: `${companion}`,
      },
    };
    if (id !== "newDialog") {
      getMessages()
        .then(function (response) {
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    if (id === "newDialog") {
      setLoader(false);
      sendMessage(JSON.stringify(soketMessageSendNewDialog));
    }
  }, []);

  // React.useEffect(() => {
  //   for (const correspondenceMessage of correspondence) {
  //     if (
  //       // correspondenceMessage.statuses !== "read" &&
  //       correspondenceMessage.user_from !== localStorage.getItem("guid")
  //     ) {
  //       sendMessage(
  //         JSON.stringify({
  //           topic: "out.message.read",
  //           unit: {
  //             dialog_id: `${correspondenceMessage.dialog_id}`,
  //             message_id: `${correspondenceMessage.message_id}`,
  //           },
  //         })
  //       );
  //     }
  //   }
  // }, [correspondence]);

  const sendMy = (id) => {
    if (id === "newDialog") {
      const soketMessageSendNewDialog = {
        topic: "out.dialog.create",
        unit: {
          user: `${companion}`,
        },
      };
      if (message !== "") {
        sendMessage(JSON.stringify(soketMessageSendNewDialog));
      }
      // axios
      //   .get(
      //     `${
      //       process.env.REACT_APP_SERVER
      //     }/api/v1/dialogs/add/?user_a=${localStorage.getItem(
      //       "guid"
      //     )}&user_b=${companion}`,
      //     {
      //       headers: { Authorization: `${localStorage.getItem("token")}` },
      //     }
      //   )
      //   .then(function (response) {
      //     const soketMessageSendNewDialog = {
      //       topic: "out.dialog.create",
      //       unit: {
      //         user: `${companion}`,
      //       },
      //     };
      //     if (message !== "") {
      //       console.log(companion)
      //       sendMessage(JSON.stringify(soketMessageSendNewDialog));
      //     }
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });
    } else {
      if (message !== "") {
        setCorrespondence((prev) => [...prev, message.unit]);
        setMessage("");
        setMessageSended(true);

        if (sendMyEdit === false) {
          if (sendMyAnswer !== false) {  

            const soketAnswerMessage = {
              topic: "out.message.add",
              unit: {
                dialog_id: `${editMessageDialogId}`,
                type: "text",
                reply: `${editMessageId}`,
                body: {
                  text: `${message}`,
                  attributes: null,
                },
              },
            };

            sendMessage(JSON.stringify(soketAnswerMessage));
            
            setEditMessageItem(false); //изменение стейта для скрытия враппера редактировнаия
            setEditMessageText(""); //обновление стейта с текстом который будем редактировать после закрытия редактирования
            setMessage(""); //обновление поля ввода после закрытия редактирования
            setEditMessageDialogId(""); // удаление ид диалога редактируемого сообщения
            reloadMessages()
            setSendMyAnswer(false)
          } else {
            sendMessage(JSON.stringify(soketMessageSend));
          }

        } else {
          const soketMessageSendEdit = {
            topic: "out.message.edit",
            unit: {
              dialog_id: `${editMessageDialogId}`,
              message_id: `${editMessageId}`,
              body: {
                text: `${message}`,
                attributes: null,
              },
            },
          };
          sendMessage(JSON.stringify(soketMessageSendEdit));
          setSendMyEdit(false);
          setEditMessageItem(false); //изменение стейта для скрытия враппера редактировнаия
          setEditMessageText(""); //обновление стейта с текстом который будем редактировать после закрытия редактирования
          setMessage(""); //обновление поля ввода после закрытия редактирования
          setEditMessageDialogId(""); // удаление ид диалога редактируемого сообщения
          reloadMessages()
            .then(function (response) {
              setLoader(false);
            })
            .catch(function (error) {
              console.log(error);
            });
        }

      }
    }
  };

  const onClicEdit = (e) => {
    setIsVisiualEditId(e.target.id);
    setIsVisiualEdit(true);
    var foo = document.getElementsByClassName("message__my");
    for (var i = 0; i < foo.length; i++) {
      foo[i].classList.remove("active");
      foo[i].classList.remove("active-top");
    }
    if (e.currentTarget.dataset.index < 3) {
      e.currentTarget.classList.add("active-top");
    } else {
      e.currentTarget.classList.add("active");
    }
  };

  const onCloseEdit = () => {
    setIsVisiualEdit(false);
    setIsVisiualEditId("");
    var foo = document.getElementsByClassName("active");
    var foos = document.getElementsByClassName("active-top");
    for (var i = 0; i < foo.length; i++) {
      foo[i].classList.remove("active");
    }
    for (var i = 0; i < foos.length; i++) {
      foos[i].classList.remove("active-top");
    }
  };

  const editMessage = (text, messageId, dialogId) => {
    setEditMessageDialogId(dialogId); // сохранение ид диалога редактируемого сообщения
    setEditMessageId(messageId); //сохранение ид сообщения которое мы редактируем
    setEditMessageText(text); //добавления текста сообщение которое редактируем в поле ввода
    // setMessage(text);
    setEditMessageItem(true); //изменение стейта для показа враппера редактировнаия
    setSendMyEdit(true); //изменение стейта для кнопки отправления
    setMessage(text);
  };

  const deleteMessage = (messageId, dialogId) => {
    setEditMessageDialogId(dialogId); // сохранение ид диалога редактируемого сообщения
    setEditMessageId(messageId); //сохранение ид сообщения которое мы редактируем
    const soketDeleteMessage = {
      topic: "out.message.delete",
      unit: {
        dialog_id: `${editMessageDialogId}`,
        message_id: `${editMessageId}`,
      },
    };
    if (editMessageId) {
      sendMessage(JSON.stringify(soketDeleteMessage));
      reloadMessages();
    }
  };

  const closeEditMessage = () => {
    setEditMessageDialogId(""); // удаление ид диалога редактируемого сообщения
    setEditMessageItem(false); //изменение стейта для скрытия враппера редактировнаия
    setEditMessageText(""); //обновление стейта с текстом который будем редактировать после закрытия редактирования
    setMessage(""); //обновление поля ввода после закрытия редактирования
    setEditMessageId(""); //удаление ид сообщения которое мы редактируем
    setSendMyEdit(false); //возвращение обычной кнопки отправления
  };

  const answerMessage = (text, messageId, dialogId) => {
    setEditMessageDialogId(dialogId); // сохранение ид диалога редактируемого сообщения
    setEditMessageId(messageId); //сохранение ид сообщения которое мы редактируем
    setEditMessageText(text); //добавления текста сообщение которое редактируем в поле ввода
    // setMessage(text);
    setEditMessageItem(true); //изменение стейта для показа враппера редактировнаия
    setSendMyAnswer(true) //изменение стейта для кнопки отправления
  };

  return (
    <>
      <UserHeader companion={companion} id={id} />
      {editMessageItem ? (
        <div className="message__edit-wrapper">
          <span>{editMessageText}</span>
          <img onClick={closeEditMessage} src={Close} />
        </div>
      ) : (
        ""
      )}
      <div className="correspondence-input__wrapper">
        <div className="correspondence-input">
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            type="text"
            placeholder="Сообщение..."
          />
          <div className="correspondence__option-wrapper">
            <button className="add-file__wrapper">
              <input type="file" onChange={onFileInputChange} />
              <img className="addImg" src={addImg} />
            </button>
            <img
              onClick={() => setSmile((prevSmile) => !prevSmile)}
              className="addSmile"
              src={addSmile}
            />
            <img className="addVoice" src={addVoice} />
            <button onClick={() => sendMy(id)}>
              <img src={send} />
            </button>
          </div>
        </div>
      </div>
      <Correspondences
        loader={loader}
        correspondence={correspondence}
        page={page}
        editMessage={editMessage}
        pageCount={pageCount}
        companion={companion}
        id={id}
        getMessages={getMessages}
        isVisiualEditId={isVisiualEditId}
        isVisiualEdit={isVisiualEdit}
        onClicEdit={onClicEdit}
        onCloseEdit={onCloseEdit}
        deleteMessage={deleteMessage}
        answerMessage={answerMessage}
        sendMyAnswer={sendMyAnswer}
      />
      <div className={smile ? "smile__wrapper" : "smile__wrapper--none"}>
        <Picker
          data={data}
          onEmojiSelect={(emoji) => setMessage(`${message} ${emoji.native}`)}
        />
      </div>
    </>
  );
}

export default Correspondence;
