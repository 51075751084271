import React from "react";
import ProfileHeader from "../../components/profile-header/index"
import ProfileInfo from "../../components/profile-info/index"

function Profile({ avatar, name, status }) {

  return (
    <>
        <ProfileHeader avatar={avatar} name={name} status={status} />
        <ProfileInfo name={name} />
    </>
  );
}

export default Profile;