import React, { useState, useCallback } from "react";
import Popup from "../popup";
import Cropper from "react-easy-crop";
import getCroppedImg from "./crop";
import axios from "axios";

const toBase64 = async (data) => {
  return new Promise ((res, rej) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = function () {
      res(reader.result)
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
      rej(error)
    };
  })
}

function UploadAvatarPopup({ visible, setVisible, setCurrentAvatar, setAvatars }) {
  const [avatarInput, setAvatarInput] = useState("");
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(avatarInput, croppedAreaPixels);
  
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const close = () => {
    setVisible(false);
    setAvatarInput("");
    setCroppedImage(null);
    setCroppedAreaPixels(null);
  };

  const onAvatarInputChange = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function () {
      setAvatarInput(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const saveImage = async () => {
    const {data} = await axios.get(`${process.env.REACT_APP_SERVER}/api/v1/avatar/add`, {
      headers: { Authorization: `${localStorage.getItem("token")}` }
    })
    const base64 = await toBase64(croppedImage);
    const formData = new FormData();
    formData.append("file", croppedImage)
    await axios.post(`${process.env.REACT_APP_STATIC_SERVER}/api/v1/avatars/upload/${data.avatar_id}`, formData, {
      headers: { Authorization: `${localStorage.getItem("token")}` },
    })
    await axios.get(`${process.env.REACT_APP_SERVER}/api/v1/avatar/selected/${data.avatar_id}`, {
      headers: { Authorization: `${localStorage.getItem("token")}` },
    })
    setTimeout(() => {
      setCurrentAvatar(URL.createObjectURL(croppedImage));
      setAvatars(prev => [...prev, {...data, is_selected: true, link: URL.createObjectURL(croppedImage)}]);
    }, 1500);
    close();
  }

  return (
    <Popup
      containerClassName={"upload-popup__avatar"}
      onClose={close}
      visible={visible}
      setVisible={setVisible}
      background={false}
    >
      {!avatarInput ? (
        <div className="upload-photo-input__wrapper">
          <label className="upload-photo-input__button" htmlFor="upload-photo">Выбрать фото</label>
          <input onChange={onAvatarInputChange} id="upload-photo" type="file" />
        </div>
      ) : croppedImage ? (
        <div>
          <img className="upload-photo__submit-go" src={URL.createObjectURL(croppedImage)} />
          <div className="upload-photo-input-button">
          <button onClick={saveImage}>Сохранить</button>
          <button onClick={close}>Отменить</button>
          </div>
        </div>
      ) : (
        <>
          <div
            style={{ position: "relative", width: "100vw", height: "200px" }}
          >
            <Cropper
              image={avatarInput}
              crop={crop}
              cropShape={"round"}
              zoom={zoom}
              aspect={3 / 3}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="upload-photo-input-button">
            <button onClick={showCroppedImage}>Далее</button>
            <button onClick={close}>Отменить</button>
          </div>
        </>
      )}
    </Popup>
  );
}

export default UploadAvatarPopup;
