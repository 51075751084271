import React from "react";
import { Link } from "react-router-dom";
import "../../styles/index.css";
import axios from "axios";
import noavatar from "../../img/noavatar.svg";
import moment from "moment";

function Chats({chatsItem}) {
  const [loader, setLoader] = React.useState(true);

  React.useEffect(() => {
    if (chatsItem) {
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    } else {
      setLoader(true);
    }
  }, []);

  return (
    <>
      {loader == true ? (
        <div className="loader__wrapper">
          <div className="loader" id="loader-1"></div>
        </div>
      ) : (
        <div className="chats__wrapper">
          {chatsItem.length == 0 ? (
            <div className="history-call__item--no">
              У вас нет открытых диалогов
            </div>
          ) : (
            <>
              <div className="chats__wrapper">
                {chatsItem.map((chatsItem, index) => (
                  <Link key={index} to={`${"/chat"}?${chatsItem?.dialog_id}?${chatsItem?.user?.guid}`}>
                    <div className="chats__item">
                      <div className="chats__item-img-wrapper">
                        <img
                          src={
                            !chatsItem.user?.avatar
                              ? noavatar
                              : chatsItem.user?.avatar
                          }
                        />
                        {chatsItem.user?.status?.messanger?.manual?.description ===
                        "Онлайн" ? (
                          <span></span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="chats__chat-text-wrapper">
                        <div className="chats__chat-name">
                          {chatsItem.user?.display_name}
                        </div>
                        <span className="chats__chat-info">
                          {chatsItem.top_message?.message?.text?.slice(0, 20) + '...'}
                        </span>
                      </div>
                      {chatsItem.unread_count ? <span className="chats__chat-counter">{chatsItem.unread_count}</span> : ''}
                      <div className="chats__chat-time">
                        {chatsItem.top_message?.dtime === null ? '' : moment(chatsItem.top_message?.dtime * 1000).format("DD.MM")}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default Chats;
