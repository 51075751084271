import React from "react";
import { Link } from "react-router-dom";
import "../../styles/index.css";
import avatar from "../../img/avatar.png";
import axios from "axios";
import noavatar from "../../img/noavatar.svg";
import useScrollBottom from '../../hooks/useScrollBottom';
import { useDebounce } from 'use-debounce';

function Contact() {
  const [contacts, setContacts] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const isScrollBottom = useScrollBottom();

  React.useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER}/api/v1.1/user?limit=20&page=${page}&offset=0`, {
        headers: { Authorization: `${localStorage.getItem("token")}` },
      })
      .then(function (response) {
        setContacts(response.data?.data?.users);
        setLoader(false);
        setPage(2)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  React.useEffect(() => {
    if (!loader && contacts.length && isScrollBottom) {
      axios
      .get(`${process.env.REACT_APP_SERVER}/api/v1.1/user?limit=20&page=${page}&offset=0`, {
        headers: { Authorization: `${localStorage.getItem("token")}` },
      })
      .then(function (response) {
        // setContacts(prev => [...prev, response.data?.data?.users]);
        // setContacts(contacts.concat(response.data?.data?.users))
        setContacts([...contacts, ...response.data?.data?.users]);
        setPage(prev => prev + 1)
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }, [isScrollBottom]);
 
  return (
    <>
      {loader == true ? (
        <div className="loader__wrapper">
          <div className="loader" id="loader-1"></div>
        </div>
      ) : (
        <div className="contact-call__wrapper">
          {contacts.length === 0 ? (
            <div className="history-call__item--no">
              Ваша записная книжка пуста
            </div>
          ) : (
            <>
              <div className="contact__wrapper">
                {contacts.map((contacts, index) => (
                  <Link key={index} to={`${"/chat"}?${contacts.dialog === null ?'newDialog' : contacts?.dialog?.dialog_id}?${contacts?.guid}`}>
                    <div className="contact__item">
                      <div className="contact__item-img-wrapper">
                        <img
                          src={!contacts.avatar ? noavatar : contacts.avatar}
                        />
                        <span></span>
                      </div>
                      <div className="contact__chat-text-wrapper">
                        <div className="contact__chat-name">
                          {contacts?.full_name}
                        </div>
                      </div>
                      <div className="contact__chat-time contact__chat-time-none">
                        12:35
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default Contact;
