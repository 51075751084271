import React from "react";
import "../../styles/index.css";
import Logo from "../../img/logo-white.svg";
import hidePass from "../../img/hidePass.svg";
import visiblePass from "../../img/hideVisible.svg";
import { useDispatch } from "react-redux";
import { login } from "../../actions/user";
import Input from "./input";
import { useNavigate } from "react-router-dom";
import { auth } from "../../actions/user";
import axios from "axios";

function Login() {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [device, setDevice] = React.useState("http_client");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    if (localStorage.getItem("token")) {
      axios
        .get(`${process.env.REACT_APP_SERVER}/api/v1/iam`, {
          headers: { Authorization: `${localStorage.getItem("token")}` },
        })
        .then(() => navigate('/home'))
        .catch(() => localStorage.removeItem("token"));
    } else {
      localStorage.removeItem("token")
    }
  }, []);

  return (
    <>
      <div className="login__wrapper">
        <div className="login__logo">
          <img src={Logo} />
        </div>

        <h2>Вход</h2>

        <form className="login__form" autoComplete="off">
          <div className="login__input-wrapper">
            <Input
              value={email}
              setValue={setEmail}
              type="text"
              placeholder="Логин"
            />
          </div>

          <div className="login__input-wrapper">
            <Input
              value={password}
              setValue={setPassword}
              type={values.showPassword ? "text" : "password"}
              placeholder="Пароль"
            />
            <img
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              className="login__hide-pass"
              src={values.showPassword ? visiblePass : hidePass}
            />
          </div>

          {localStorage.error && <div className="login__error">
            <span>Ошибка авторизации</span>
          </div>}

          <div className="login__checked-wrapper">
            <label>
              <input type="checkbox" /> Запомнить меня
            </label>
          </div>

          <div className="login__button-wrapper">
            <button
              onClick={(e) => {
                e.preventDefault()
                dispatch(login(email, password, device))
              }}
              type="submit"
            >
              Вход
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default Login;
