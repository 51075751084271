import React from "react";
import { Link } from "react-router-dom";
import "../../styles/index.css";
import axios from "axios";
import noavatar from "../../img/noavatar.svg";
import delfav from "../../img/delfav.svg";

function ChatsFav() {
  const [chatsFav, SetChatsFav] = React.useState("");
  const [loader, setLoader] = React.useState(true);

  React.useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER}/api/v1/favorites`, {
        headers: { Authorization: `${localStorage.getItem("token")}` },
      })
      .then(function (response) {
        SetChatsFav(response.data.catalog_list);
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const delFavorite = (id) => {
    axios
    .post(`${process.env.REACT_APP_SERVER}/api/v1/favorites/del`,
    {user: id},
     {headers: { Authorization: `${localStorage.getItem("token")}` },
    })
    .then(function (response) {
          axios
      .get(`${process.env.REACT_APP_SERVER}/api/v1/favorites`, {
        headers: { Authorization: `${localStorage.getItem("token")}` },
      })
      .then(function (response) {
        SetChatsFav(response.data.catalog_list);
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  return (
    <>
      {loader ? (
        <div className="loader__wrapper">
          <div className="loader" id="loader-1"></div>
        </div>
      ) : (
        <div className="chats__wrapper">
          {chatsFav.length === 0 ? (
            <div className="history-call__item--no">
              У вас нет избранных чатов
            </div>
          ) : (
            <>
              <div className="chats__wrapper">
                {chatsFav.map((chatsFav, index) => (
                  <div key={index}>
                    <div className="chats__item">
                      <div className="chats__item-img-wrapper">
                        <img
                        alt="avatar"
                          src={
                            !chatsFav?.avatar
                              ? noavatar
                              : chatsFav?.avatar
                          }
                        />
                        {chatsFav?.status?.messanger?.manual?.description === "Онлайн"
                        ?
                        <span></span>
                        :
                        ''
                        }
                        
                      </div>
                       <Link className="chats__chat-text-wrapper" key={index} to={`${"/chat"}?${chatsFav.dialog?.dialog_id}?${chatsFav.guid}`}>
                        <div className="chats__chat-name">
                          {chatsFav?.display_name}
                        </div>
                        <span className="chats__chat-info">
                          
                        </span>
                      </Link>
                      <div  onClick={() => delFavorite(chatsFav.guid)} className="chats__chat-time"><img src={delfav} alt="delete favorite" /></div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default ChatsFav;
