const SET_INCOMING_CALL_POPUP = "SET_INCOMING_CALL_POPUP";
const SET_CALL_POPUP = "SET_CALL_POPUP";
const SET_COMPANION = "SET_COMPANION";
const SET_PHONE_DATA = "SET_PHONE_DATA";
const SET_VIDEO = "SET_VIDEO_DATA";

const initialState = {
    incomingCallPopup: false,
    callPopup: false,
    companion: null,
    video: false,
}

export default function phoneReducer(state = initialState, action) {
    switch (action.type) {
        case SET_INCOMING_CALL_POPUP:
            return {...state, incomingCallPopup: action.payload}
        case SET_CALL_POPUP:
            return {...state, callPopup: action.payload}
        case SET_COMPANION:
            return {...state, companion: action.payload};
        case SET_VIDEO:
            return {...state, video: action.payload};    
        case SET_PHONE_DATA:
            return {...state, ...action.payload}
        default:
            return state
    }
}

export const setIncomingCallPopup = payload => ({type: SET_INCOMING_CALL_POPUP, payload});
export const setCallPopup = payload => ({type: SET_CALL_POPUP, payload});
export const setCompanion = payload => ({type: SET_COMPANION, payload});
export const setPhoneData = payload => ({type: SET_PHONE_DATA, payload});
export const setVideo = payload => ({type: SET_VIDEO, payload});
