import React from "react";
import ProfileHeader from "../../components/profile-header/index"
import HistoryCall from "../../components/history-call/index"


function History({name, status, avatar}) {
  
  return (
    <>
        <ProfileHeader avatar={avatar} name={name} status={status} />
        <HistoryCall />
    </>
  );
}

export default History;