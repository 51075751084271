import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import "../../styles/index.css";
import noavatar from "../../img/noavatar.svg";
import back from "../../img/back.svg";
import call from "../../img/call.svg";
import info from "../../img/info.svg";
import addfavorite from "../../img/addfavorite.svg";
import historycall from "../../img/historycall.svg";
import axios from "axios";
import videocall from "../../img/videocall.svg";
import delfav from "../../img/delfav.svg";
import useSIP from "../../hooks/useSIP";
import { useDispatch, useSelector } from "react-redux";
import { setCallPopup, setPhoneData } from "../../reducers/phone-reduser";

function UserHeader({ companion, id }) {
  const navigate = useNavigate();
  const [isActive, setisActive] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState("");
  const [favorite, setFavorite] = React.useState(false);
  const dispatch = useDispatch();
  const { SIP } = useSIP({});

  const onPhoneCallClick = () => {
    dispatch(
      setPhoneData({ callPopup: true, companion: userInfo, video: false })
    );
    SIP.phone.call(userInfo.work_phone, {
      ...SIP.callOptions,
      rtcOfferConstraints: {
        offerToReceiveAudio: 1,
        offerToReceiveVideo: 0,
      },
    });
  };

  const onPhoneVideoCallClick = () => {
    dispatch(
      setPhoneData({ callPopup: true, companion: userInfo, video: true })
    );
    SIP.phone.call(userInfo.work_phone, {
      ...SIP.callOptions,
      rtcOfferConstraints: {
        offerToReceiveAudio: 1,
        offerToReceiveVideo: 1,
      },
    });
  };

  React.useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER}/api/v1/user/${
          window.location.pathname === "/chat" ? companion : id
        }`,
        {
          headers: { Authorization: `${localStorage.getItem("token")}` },
        }
      )
      .then(function (response) {
        setUserInfo(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const addFavorite = (companion) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER}/api/v1/favorites/add`,
        { user: companion },
        { headers: { Authorization: `${localStorage.getItem("token")}` } }
      )
      .then(function (response) {
        setFavorite(true);
        axios
          .get(
            `${process.env.REACT_APP_SERVER}/api/v1/user/${
              window.location.pathname === "/chat" ? companion : id
            }`,
            {
              headers: { Authorization: `${localStorage.getItem("token")}` },
            }
          )
          .then(function (response) {
            setUserInfo(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const delFavorite = (id) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER}/api/v1/favorites/del`,
        { user: id },
        { headers: { Authorization: `${localStorage.getItem("token")}` } }
      )
      .then(function (response) {
        setFavorite(false);
        axios
          .get(
            `${process.env.REACT_APP_SERVER}/api/v1/user/${
              window.location.pathname === "/chat" ? companion : id
            }`,
            {
              headers: { Authorization: `${localStorage.getItem("token")}` },
            }
          )
          .then(function (response) {
            setUserInfo(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="profile-header__wrapper">
      <Link onClick={() => navigate(-1)}>
        <img src={back} />
      </Link>

      <div className="profile-header__info">
        <Link to={`/user?${companion}?${id}`}>
          <img src={!userInfo.avatar ? noavatar : userInfo.avatar} />
        </Link>
        <div className="profile-header__text">
          <div className="profile-header__name">{userInfo.display_name}</div>
          <div className="profile-header__status">
            {userInfo.status?.messanger?.manual?.description}
          </div>
        </div>
        <div className="user-header__button-wrapper">
          <div className="user-header__button">
            <img
              onClick={() => setisActive((prevActive) => !prevActive)}
              src={info}
            />
          </div>
          <div onClick={onPhoneCallClick} className="user-header__button">
            <img src={call} />
          </div>
        </div>
        <div
          className={
            isActive
              ? "user-header__option-wrapper"
              : "user-header__option-wrapper--none"
          }
        >
          <div
            onClick={
              userInfo.is_favorites === true
                ? () => delFavorite(companion)
                : () => addFavorite(companion)
            }
            className="user-header__option-text"
          >
            {userInfo.is_favorites === true ? (
              <>
                <span>Убрать из избранного</span>
                <img src={delfav} />
              </>
            ) : (
              <>
                <span>Добавить в избранное</span>
                <img src={addfavorite} />
              </>
            )}
          </div>

          <div className="user-header__option-text">
            <Link to={`${"/chat/history"}?${companion}`}>История звонков</Link>
            <img src={historycall} />
          </div>
          <div
            onClick={onPhoneVideoCallClick}
            className="user-header__option-text"
          >
            <span>Видеозвонок</span>
            <img src={videocall} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserHeader;
