import React from "react";
import { Link } from "react-router-dom";
import '../../styles/index.css';
import myAvatar from "../../img/myAvatar.png";
import search from "../../img/search.svg";
import noavatar from "../../img/noavatar.svg";

function IndexHeader({avatar}) {
  
  return (
      <div className="index-header__wrapper">
        <Link to="/profile"><img src={!avatar ? noavatar : avatar} /></Link>

        <div className="index-header__search">
            <input
              autoComplete="off"
              placeholder="Поиск"
              type="text"
              required
              //   value={this.state.email}
              //   onChange={this.onChangeEmail}
            />
            <img src={search} />
          </div>
      </div>
  );
}

export default IndexHeader;