import { useState, useEffect } from "react";

const useTimer = (started) => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (started) {
      const i = setInterval(() => {
        setSeconds((prev) => prev + 1);
      }, 1000);
  
      return () => {
        setHours(0)
        setMinutes(0)
        setSeconds(0)
        clearInterval(i);
      };
    }
  }, [started]);

  useEffect(() => {
    if (seconds >= 60) {
      setMinutes((prev) => prev + 1);
      setSeconds(0)
    }
  }, [seconds]);

  useEffect(() => {
    if (minutes >= 60) {
      setHours((prev) => prev + 1);
    }
  }, [minutes]);

  return [hours, minutes, seconds];
}

export default useTimer;
