import axios from "axios";
import { setUser } from "../reducers/user-reduser";
import useWebSocket from "react-use-websocket";
import socket from "../soket/index";

export const login = (login, pwd, device) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/v1/auth/login`,
        {
          login,
          pwd,
          device,
        }
      );
      dispatch(setUser(response.data));
      localStorage.setItem("token", response.data["session token"]);
      window.location.replace("/home");
      useWebSocket(socket().connection.auth);
    } catch (e) {
      console.log(e)
    }
  };
};

export const auth = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/api/v1/auth/check_verif`,
        { headers: { Authorization: `${localStorage.getItem("token")}` } }
      );
      dispatch(setUser(response.data));
      localStorage.setItem("guid", response.data.guid);
    } catch (e) {
      // alert(e.response.status);
      localStorage.removeItem("guid");
    }
  };
};

// export const iAm =  () => {
//     return async dispatch => {
//         try {
//             const response = await axios.get(`https://test-server.abs95.ru:4444/api/v1/iam`
//             )
//             dispatch(setUser(response.data))
//             localStorage.setItem('Iam', response.data)
//         } catch (e) {
//             alert(e.response.data.message)
//             localStorage.removeItem('token')
//         }
//     }
// }
