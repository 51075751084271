import { useCallback, useEffect, useRef, useState } from "react";
import SIP from "../SIP";

const useSIP = (
  {
    onIncomingCall,
    onAccepted,
    onProgress,
    onEnded,
    localVideoRef,
    remoteVideoRef,
  } = {},
  defaultVideoValue = false
) => {
  const [audio, setAudio] = useState(true);
  const [video, setVideo] = useState(defaultVideoValue);
  const [sessionConfirmed, setSessionConfirmed] = useState(false);
  const onSessionConfirmed = useCallback(() => {
    setSessionConfirmed(true);
  }, []);

  const onAddTrack = useCallback((ev) => {
    const unlocalStreamVideo = new MediaStream();
    console.log(unlocalStreamVideo)
    console.log(ev, "addTrack")
    if (ev && remoteVideoRef?.current) {
      console.log('have 1')
      if (ev.streams && ev.streams[0]) {
        console.log('stream 2')
          remoteVideoRef.current.srcObject = ev.streams[0];
      } else {
        console.log('track stream 3')
          const inboundStream = new MediaStream(ev.track);
          remoteVideoRef.current.srcObject = inboundStream;
      }
      }
  }, []);

  const connectToSIP = useCallback(async () => {
    SIP.callOptions.mediaConstraints = { audio, video };
    if (!SIP.isConnected) {
      await SIP.connect();
    }
    const newEvents = { onIncomingCall, onAccepted, onProgress, onEnded };
    for (const eventName in newEvents) {
      if (newEvents[eventName]) {
        SIP.events[eventName] = [
          ...SIP.events[eventName],
          newEvents[eventName],
        ];
      }
    }
    SIP.events.onSessionConfirmed = [
      ...SIP.events.onSessionConfirmed,
      onSessionConfirmed,
    ];
    SIP.events.onAddTrack = [
      ...SIP.events.onAddTrack,
      onAddTrack,
    ];
  }, []);

  useEffect(() => {
    void connectToSIP();
  }, []);

  useEffect(() => {
    setVideo(defaultVideoValue);
  }, [defaultVideoValue]);

  useEffect(() => {
    if (video && sessionConfirmed) {

      // const localStreamVideo = new MediaStream();
      // for (const receiver of SIP.session.connection.getReceivers()) {
      //   if (receiver?.track?.kind !== "audio") {
      //     unlocalStreamVideo.addTrack(receiver.track);
      //     console.log(receiver.track);
      //   }
      // }

      // for (const sender of SIP.session.connection.getSenders()) {
      //   if (sender?.track?.kind !== "audio") {
      //     localStreamVideo.addTrack(sender.track);
      //     console.log(sender.track);
      //   }
      // }
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((localStream) => {
          if (localVideoRef?.current) {
            localVideoRef.current.srcObject = localStream;
          }
          localStream
            .getTracks()
            .forEach((track) =>
              SIP.session.connection.addTrack(track, localStream)
            );
        });
    }
  }, [video, sessionConfirmed]);

  return { SIP, audio, setAudio, video, setVideo };
};

export default useSIP;


// import { useCallback, useEffect, useRef, useState } from "react";
// import SIP from "../SIP";

// const useSIP = (
//   {
//     onIncomingCall,
//     onAccepted,
//     onProgress,
//     onEnded,
//     localVideoRef,
//     remoteVideoRef,
//   } = {},
//   defaultVideoValue = false
// ) => {
//   const [audio, setAudio] = useState(true);
//   const [video, setVideo] = useState(defaultVideoValue);
//   const [sessionConfirmed, setSessionConfirmed] = useState(false);
//   const onSessionConfirmed = useCallback(() => {
//     setSessionConfirmed(true);
//   }, []);

//   const unlocalStreamVideoRef = useRef(new MediaStream)

//   const onAddTrack = useCallback((ev) => {
//     // const unlocalStreamVideo = new MediaStream();
//     // console.log(ev, "addTrack")
//     if (ev && remoteVideoRef?.current) {
//       // console.log('have 1')
//       // if (ev.streams && ev.streams[0]) {
//       //   console.log('stream 2')
//       //     remoteVideoRef.current.srcObject = ev.streams[0];
//       // } else {
//       //   console.log('track stream 3')
//           // const inboundStream = new MediaStream(ev.track);
//           // unlocalStreamVideoRef.current.addTrack(ev.track)
//           // remoteVideoRef.current.srcObject = unlocalStreamVideoRef.current;
//       // }
//       }
//   }, []);

//   const connectToSIP = useCallback(async () => {
//     SIP.callOptions.mediaConstraints = { audio, video };
//     if (!SIP.isConnected) {
//       await SIP.connect();
//     }
//     const newEvents = { onIncomingCall, onAccepted, onProgress, onEnded };
//     for (const eventName in newEvents) {
//       if (newEvents[eventName]) {
//         SIP.events[eventName] = [
//           ...SIP.events[eventName],
//           newEvents[eventName],
//         ];
//       }
//     }
//     SIP.events.onSessionConfirmed = [
//       ...SIP.events.onSessionConfirmed,
//       onSessionConfirmed,
//     ];
//     SIP.events.onAddTrack = [
//       ...SIP.events.onAddTrack,
//       onAddTrack,
//     ];
//   }, []);

//   useEffect(() => {
//     void connectToSIP();
//   }, []);

//   useEffect(() => {
//     setVideo(defaultVideoValue);
//   }, [defaultVideoValue]);

//   useEffect(() => {
//     if (video && sessionConfirmed) {
//       const localStream_video = new MediaStream();
//       const unlocalStream_video = new MediaStream();
//       SIP.session.connection.getReceivers().forEach(function (receiver) {
//           if (receiver.track.kind != 'audio') {
//               unlocalStream_video.addTrack(receiver.track)

//               console.log(receiver.track)

//           }
//       })
//       SIP.session.connection.getSenders().forEach(function (receiver) {
//           if (receiver && receiver.track && receiver.track.kind != 'audio') {
//               localStream_video.addTrack(receiver.track)

//               console.log(receiver.track)

//           }
//       })
//       if (localVideoRef?.current) {
//         localVideoRef.current.srcObject = localStream_video
//       }
//       if (remoteVideoRef?.current) {
//         remoteVideoRef.current.srcObject = unlocalStream_video
//       }
//       // const localStreamVideo = new MediaStream();
//       // for (const receiver of SIP.session.connection.getReceivers()) {
//       //   if (receiver?.track?.kind !== "audio") {
//       //     unlocalStreamVideo.addTrack(receiver.track);
//       //     console.log(receiver.track);
//       //   }
//       // }

//       // for (const sender of SIP.session.connection.getSenders()) {
//       //   if (sender?.track?.kind !== "audio") {
//       //     localStreamVideo.addTrack(sender.track);
//       //     console.log(sender.track);
//       //   }
//       // }

//       // navigator.mediaDevices
//       //   .getUserMedia({ video: true })
//       //   .then((localStream) => {
//       //     if (localVideoRef?.current) {
//       //       localVideoRef.current.srcObject = localStream;
//       //     }
//       //     localStream
//       //       .getTracks()
//       //       .forEach((track) =>
//       //         SIP.session.connection.addTrack(track, localStream)
//       //       );
//       //   });
//     }
//   }, [video, sessionConfirmed]);

//   return { SIP, audio, setAudio, video, setVideo };
// };

// export default useSIP;
