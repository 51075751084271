import React, { useState, useCallback, useRef } from "react";
import "../../styles/index.css";
import { useDispatch, useSelector } from "react-redux";
import CallOk from "../../img/callok.svg";
import CallNo from "../../img/callno.svg";
import Popup from "../popup";
import { setCallPopup, setPhoneData } from "../../reducers/phone-reduser";
import useSIP from "../../hooks/useSIP";
import useTimer from "../../hooks/useTimer";
import noavatar from "../../img/noavatar.svg";

const getWithZeroes = (n) => {
  return n < 10 ? "0" + n : n;
};

function CallPopup() {
  const { callPopup, companion, video: isVideoCall } = useSelector(
    (state) => state.phone
  );
  const dispatch = useDispatch();
  const setVisible = useCallback(
    (state) => {
      dispatch(setCallPopup(state));
    },
    [callPopup]
  );

  const [accepted, setAccepted] = useState(false);
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const [hours, minutes, seconds] = useTimer(accepted);

  const terminateCall = () => {
    setVisible(false);
    setAccepted(false);
    SIP.closeSession();
  };

  const { SIP, video, setVideo, audio, setAudio } = useSIP(
    {
      onEnded: terminateCall,
      onAccepted() {
        setAccepted(true);
      },
      localVideoRef,
      remoteVideoRef,
    },
    isVideoCall
  );

  return (
    <Popup visible={callPopup}>
      <div className="call-coming__wrapper">
        <div className="call-incoming">
          <img src={noavatar} alt="Аватар" />
          <div className="call-incoming--name">{companion?.display_name}</div>
          {!accepted && (
            <div className="call-incoming--status">Соединение...</div>
          )}
          {accepted && (
            <div className="call-incoming--status">
              {getWithZeroes(hours)} : {getWithZeroes(minutes)} :{" "}
              {getWithZeroes(seconds)}
            </div>
          )}
          {video && (
            <>
              <video
                className="video__wrapper"
                autoPlay={true}
                muted={true}
                ref={localVideoRef}
              />
              <video
                className="video__wrapper"
                autoPlay={true}
                muted={true}
                ref={remoteVideoRef}
                id={"remoteStream"}
              />
            </>
          )}
          <div className="call-incoming__button-wrapper call-incoming__button-wrapper--one">
            <button onClick={terminateCall}>
              <img src={CallNo} />
            </button>
          </div>
        </div>
      </div>
    </Popup>
  );
}

export default CallPopup;
