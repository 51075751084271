import React from "react";
import { Link } from "react-router-dom";
import "../../styles/index.css";
import InCall from "../../img/incall.svg";
import IsCall from "../../img/iscall.svg";
import MissCall from "../../img/misscall.svg";
import axios from "axios";
import noavatar from "../../img/noavatar.svg";

function HistoryCallChat() {
  const [callHistory, setCallHistory] = React.useState("");
  const [loader, setLoader] = React.useState(true);
  const companion = window.location.href.split("?")[1];

  React.useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER}/api/v1/calls?data_start=0&data_stop=1678692817&user_b=${companion}`, {
        headers: { Authorization: `${localStorage.getItem("token")}` },
      })
      .then(function (response) {
        setCallHistory(response.data.user_calls);
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return loader == true ? (
    <div className="loader__wrapper">
      <div className="loader" id="loader-1"></div>
    </div>
  ) : (
    <div className="history-call__wrapper">
      {callHistory.length == 0 ? (
        <div className="history-call__item--no">У вас нет истории звонков</div>
      ) : (
        <>
          {callHistory.map((callHistory, index) => (
            <Link key={index}>
              <div className="history-call__item">
                <div className="history-call__item-img-wrapper">
                  <img
                    src={
                      !callHistory?.caller?.avatar
                        ? noavatar
                        : callHistory?.caller?.avatar
                    }
                  />
                  <span></span>
                </div>
                <div className="history-call__text-wrapper">
                  <div className="history-call__chat-name">
                    {callHistory?.caller?.display_name}
                  </div>
                  <div className="history-call__chat-info">
                    {callHistory?.datatime_expand?.data}{" "}
                    {callHistory?.datatime_expand?.mount}{" "}
                    {callHistory?.datatime_expand?.year}{" "}
                    {callHistory?.datatime_expand?.time},{" "}
                    <span>{callHistory?.duration}минут</span>
                  </div>
                </div>
                {callHistory.status === "входящий" ? <img src={InCall} /> : ""}
                {callHistory.status === "отмененный" ? (
                  <img src={MissCall} />
                ) : (
                  ""
                )}
                {callHistory.status === "исходящий" ? <img src={IsCall} /> : ""}
              </div>
              <audio src={callHistory?.content?.link} controls="true"></audio>
            </Link>
          ))}
        </>
      )}
    </div>
  );
}

export default HistoryCallChat;
