import React from "react";
import { Link } from "react-router-dom";
import "../../styles/index.css";
import axios from "axios";
import noavatar from "../../img/noavatar.svg";

function ConferenceItem() {
  const [contacts, setContacts] = React.useState("");
  const [loader, setLoader] = React.useState(true);

  React.useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER}/api/v1/user`, {
        headers: { Authorization: `${localStorage.getItem("token")}` },
      })
      .then(function (response) {
        setContacts(response.data?.users);
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <>
      {loader == true ? (
        <div className="loader__wrapper">
          <div className="loader" id="loader-1"></div>
        </div>
      ) : (
        <div className="conference__wrapper">
          {contacts.length == 0 ? (
            <div className="history-call__item--no">
              Ваша записная книжка пуста
            </div>
          ) : (
            <>
              <div className="conference__wrapper">
                {contacts.map((contacts, index) => (
                  <div key={index} className="conference__item">
                    <div className="conference__item-img-wrapper">
                      <img
                        src={
                          !contacts.avatar ? noavatar : contacts.avatar
                        }
                      />
                      <span></span>
                    </div>
                    <div className="conference__text-wrapper">
                      <div className="conference__chat-name">
                        {contacts?.full_name}
                      </div>
                    </div>
                    <input type="checkbox" />
                  </div>
                ))}
              </div>
              <button className="conference__ready" type="submit">
                Начать конференцию
              </button>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default ConferenceItem;
