import React from "react";
import ProfileHeader from "../../components/profile-header/index"
import HistoryCallChat from "../../components/chat-history/index"


function ChatHistory({name, status, avatar}) {
  
  return (
    <>
        <ProfileHeader avatar={avatar} name={name} status={status} />
        <HistoryCallChat />
    </>
  );
}

export default ChatHistory;