import React, { useState, useCallback } from "react";
import "../../styles/index.css";
import CallOk from "../../img/callok.svg";
import CallNo from "../../img/callno.svg";
import CallOkVideo from "../../img/CallOkVideo.svg";
import Popup from "../popup";
import { useDispatch, useSelector } from "react-redux";
import {
  setIncomingCallPopup,
  setPhoneData,
} from "../../reducers/phone-reduser";
import useSIP from "../../hooks/useSIP";
import noavatar from "../../img/noavatar.svg";

function CallIncomingPopup() {
  const { incomingCallPopup, companion } = useSelector((state) => state.phone);
  const dispatch = useDispatch();
  const setVisible = useCallback(
    (state) => {
      dispatch(setIncomingCallPopup(state));
    },
    [incomingCallPopup]
  );

  const terminateCall = () => {
    setVisible(false);
    SIP.closeSession();
  };

  const { SIP, setVideo, video } = useSIP({
    onEnded: terminateCall,
  });

  const acceptCall = () => {
    dispatch(
      setPhoneData({ incomingCallPopup: false, callPopup: true, video: false })
    );
    SIP.session.answer(SIP.callOptions);
  };

  const acceptVideoCall = () => {
    setVideo(true);
    dispatch(
      setPhoneData({ incomingCallPopup: false, callPopup: true, video: true })
    );
    SIP.session.answer({ ...SIP.callOptions, video: true });
  };

  return (
    <Popup visible={incomingCallPopup}>
      <div className="call-incoming__wrapper">
        <div className="call-incoming">
          <span>Входящий звонок</span>
          <img src={noavatar} alt="Аватар" />
          <div className="call-incoming--name">
            {SIP.session?._remote_identity?._display_name}
          </div>
          <div className="call-incoming__button-wrapper">
            {SIP.session?._request?.body?.indexOf("video") !== -1 ? (
              <button className="video" onClick={acceptVideoCall}>
                <img src={CallOkVideo} />
              </button>
            ) : (
              <button onClick={acceptCall}>
                <img src={CallOk} />
              </button>
            )}
            <button onClick={terminateCall}>
              <img src={CallNo} />
            </button>
          </div>
        </div>
      </div>
    </Popup>
  );
}

export default CallIncomingPopup;
