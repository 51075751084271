// const url = (endpoint, searchParams) => {
//   const queryString = Object.entries(searchParams)
//     .map((p) => `${encodeURIComponent(p[0])}=${encodeURIComponent(p[1])}`)
//     .join("&");

//   return `${endpoint}?$${queryString}`;
// };

const path = `${process.env.REACT_APP_SERVER_SOKET}`;

const socket = () => {
  return {
    connection: {
      auth: `${path}`,
    },
  };
};

export default socket;
