import React, { useEffect } from "react";
import "../../styles/index.css";
import Home from "../../pages/home";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "../../pages/login";
import Profile from "../../pages/profile";
import History from "../../pages/history";
import Setting from "../../pages/setting";
import ChatHistory from "../../pages/chat-history";
import Conference from "../../pages/conference";
import Contacts from "../../pages/contacts";
import Favorite from "../../pages/favorite";
import Correspondence from "../../pages/correspondence";
import User from "../../pages/user";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../actions/user";
import axios from 'axios'
import PrivateRoute from "../private-route";
import CallIncomingPopup from "../incoming-call-popup/index"
import CallPopup from "../call-popup/index"

function App() {
  const [name, setName] = React.useState('');
  const [avatar, setAvatar] = React.useState('');
  const [status, setStatus] = React.useState('');
  const location = useLocation();

    useEffect(() => {
      axios.get(`${process.env.REACT_APP_SERVER}/api/v1/iam`, {headers: {Authorization:`${localStorage.getItem('token')}`}})
      .then(function (response) {
          setName(response.data?.i_am?.display_name);
          setAvatar(response.data.i_am.avatar)
          setStatus(response.data?.i_am?.status?.messanger?.manual?.description)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [location.pathname]);


  return (
    <>
    <Routes>
      
      <Route path="/home" element={<PrivateRoute><Home avatar={avatar} /></PrivateRoute>} />

      <Route path="/" element={<Login />} />

      <Route path="/profile" element={<PrivateRoute><Profile avatar={avatar} name={name} status={status} /></PrivateRoute>} />

      <Route path="/profile/history" element={<PrivateRoute><History avatar={avatar} name={name} status={status} /></PrivateRoute>} />

      <Route path="/profile/setting" element={<PrivateRoute><Setting setAvatar={setAvatar} avatar={avatar} name={name} status={status} /></PrivateRoute>} />

      <Route path="/chat/history" element={<PrivateRoute><ChatHistory avatar={avatar} name={name} status={status} /></PrivateRoute>} />

      <Route path="/conference" element={<PrivateRoute><Conference avatar={avatar} /></PrivateRoute>} />

      <Route path="/contacts" element={<PrivateRoute><Contacts avatar={avatar} /></PrivateRoute>} />

      <Route path="/favorites" element={<PrivateRoute><Favorite avatar={avatar} /></PrivateRoute>} />

      <Route path="/chat" element={<PrivateRoute><Correspondence avatar={avatar} /></PrivateRoute>} />

      <Route path="/user" element={<PrivateRoute><User avatar={avatar} /></PrivateRoute>} />
    </Routes>
    <CallIncomingPopup />
    <CallPopup />
    </>
  );
}

export default App;
