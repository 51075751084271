import { useState, useRef, useEffect } from "react";
import { useDebounce } from 'use-debounce';

function useScrollBottom(delay=700) {
  const [isBottom, setIsBottom] = useState(false);
  const [debouncedValue] = useDebounce(isBottom, delay);

  useEffect(() => {
    const handler = () => {
      const max = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
      setIsBottom(Math.ceil(window.scrollY + window.innerHeight) === max);
    };
    handler();

    window.addEventListener("scroll", handler);

    return () => window.removeEventListener("scroll", handler);
  }, []);

  return debouncedValue;
}

export default useScrollBottom;
