import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import "../../styles/index.css";
import back from "../../img/back.svg";
import noavatar from "../../img/noavatar.svg";

function ProfileHeader({ avatar, name, status }) {
  const navigate = useNavigate();

  return (
    <>
      {name == 0 ? (
        <div className="loader__wrapper">
          <div className="loader" id="loader-1"></div>
        </div>
      ) : (
        <div className="profile-header__wrapper">
          <Link onClick={() => navigate(-1)}>
            <img src={back} />
          </Link>
          <div className="profile-header__info">
            <img src={!avatar ? noavatar : avatar} />
            <div className="profile-header__text">
              <div className="profile-header__name">
                {name.split(" ").slice(0, -1).join(" ")}
              </div>
              <div className="profile-header__status">{status}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProfileHeader;
