import {applyMiddleware, combineReducers, createStore} from "redux";
import {composeWithDevTools } from 'redux-devtools-extension'
import thunk from "redux-thunk";
import phoneReducer from "./phone-reduser";
import userReducer from "./user-reduser";

const rootReducer = combineReducers({
    user: userReducer,
    phone: phoneReducer
})

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
