import React from "react";
import IndexHeader from "../../components/index-header/index";
import Chats from "../../components/chats/index";
import Menu from "../../components/menu/index";
import { useDispatch } from "react-redux";
import { auth } from "../../actions/user";
import useWebSocket from "react-use-websocket";
import socket from "../../soket/index";

  // const dispatch = useDispatch();
  // const { sendMessage, lastMessage, readyState } = useWebSocket(
  //   `${process.env.REACT_APP_SERVER_SOKET}`
  // );
  // const soketAuth = {
  //   topic: "out.auth.check",
  //   token: `${localStorage.getItem("token")}`,
  // };

  // React.useEffect(() => {
  //   dispatch(auth());
  //   sendMessage(JSON.stringify(soketAuth));
  // }, []);

function Home({ avatar }) {
  const [chatsItem, setChatsItem] = React.useState([]);

  const soketAuth = {
    topic: "out.auth.check",
    token: `${localStorage.getItem("token")}`,
  };
  const { sendMessage, lastMessage, readyState } = useWebSocket(
    `${process.env.REACT_APP_SERVER_SOKET}`,
    {
      onMessage(event) {
        let chat = JSON.parse(event.data);
        if (chat.topic === "in.dialog.list") {
          setChatsItem(chat.unit.dialogs)
        }
      },
    }
  );
  const dispatch = useDispatch();
    React.useEffect(() => {
    dispatch(auth());
    sendMessage(JSON.stringify(soketAuth));
  }, []);

  return (
    <>
      <IndexHeader avatar={avatar} />
      <Chats chatsItem={chatsItem} />
      <Menu />
    </>
  );
}

export default Home;
