import React from "react";
import UserHeader from "../../components/user-header"
import UserInfo from "../../components/user-info"
import axios from "axios";

function User() {
  const id = window.location.href.split("?")[1];
  const companion = window.location.href.split("?")[2];

  return (
    <>
    <UserHeader companion={companion} id={id} />
    <UserInfo companion={companion} id={id} />
    </>
  );
}

export default User;