import React from "react";
import { Link } from "react-router-dom";
import "../../styles/index.css";
import menuOne from "../../img/menu1.svg";
import menuTwo from "../../img/menu2.svg";
import menuThree from "../../img/menu3.svg";
import menuFour from "../../img/menu4.svg";
import menuOnea from "../../img/menu1a.svg";
import menuTwoa from "../../img/menu2a.svg";
import menuThreea from "../../img/menu3a.svg";
import menuFoura from "../../img/menu4a.svg";


function Menu() {
  return (
    <div className="menu__wrapper">
      <ul className="menu__list">
          <li className="menu__item"><Link className={window.location.pathname === '/contacts' ? "menu__item-nav active" : "menu__item-nav"} to="/contacts"><div><img src={window.location.pathname === '/contacts' ? menuOnea :menuOne} /></div><span>Cправочник</span></Link></li>
          <li className="menu__item"><Link className={window.location.pathname === '/home' ? "menu__item-nav active" : "menu__item-nav"} to="/home"><div><img src={window.location.pathname === '/home' ? menuTwoa : menuTwo} /></div><span>Чаты</span></Link></li>
          <li className="menu__item"><Link className={window.location.pathname === '/conference' ? "menu__item-nav active" : "menu__item-nav"} to="/conference"><div><img src={window.location.pathname === '/conference' ? menuThreea : menuThree} /></div><span>Конференции</span></Link></li>
          <li className="menu__item"><Link className={window.location.pathname === '/favorites' ? "menu__item-nav active" : "menu__item-nav"} to="/favorites"><div><img src={window.location.pathname === '/favorites' ? menuFoura : menuFour} /></div><span>Избранное</span></Link></li>
      </ul>
      <a className="menu__message">Написать</a>
    </div>
  );
}

export default Menu;
